.login-container-root {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .login-box {
    background-color: var(--thirty-color);
    padding: 12px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .input-container {
      input {
        margin-bottom: 6px;
      }
    }
  }

  .email-msg {
    font-size: 12px;
  }

  .reset {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 6px;
  }
}